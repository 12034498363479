import { useAuthStore } from '~~/stores/auth'

export const useIntervalRefresh = (
  refresh: () => void | Promise<void>,
  interval?: MaybeRefOrGetter<number>,
  busy?: MaybeRefOrGetter<boolean>,
): void => {
  const { isReady } = storeToRefs(useAuthStore())
  const isBusy = toRef(busy || false)

  useIntervalFn(() => isReady.value && !isBusy.value && refresh(), interval)
}
